import React, { Component } from 'react';
import request from 'request';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';


class Testmodule extends Component {

    state = {
        resultData : ''
    }

    static defaultProps = {
        key: "d1195617dfab1cf1ab1b42a963544356201393de8eb2a7d8b8f704906bc6569e",
        domain: 'https://allsportsapi.com/api/basketball/'

    }
    getKey = () => {
        return this.props.key;
    }

    getDomain = () => {
        return this.props.domain;
    }

    callback = (error, response, body) => {
        if (!error && response.statusCode == 200) {
          var info = JSON.parse(body);
          console.log(info);
        }
      }
       

    requestJson = () => {
        return new Promise((resolve, reject) => {
            var options = {
                url: 'http://data.nba.net',
                headers: {
                  'Accept': 'application/json',
                  'Accept-Encoding': 'gzip, deflate, br',                 
                }


                
              };
            request(options, this.callback);



            // const key = this.getKey();
            // axios.get('http://data.nba.net/10s/prod/v1/today.json')
            // .then(function (response) {
            //     console.log(response);
            //     resolve(response.data);
    
            // })
            // .catch(function (error) {
            //     console.log(error);
            //     reject(error);
            // })
        });
    }

    async componentDidMount() {
        console.log('componentDidMount');
        
        const result = await this.requestJson();
        console.log('result : ', result);
        this.setState({
            resultData: result
        });
        // console.log('result2 : ', this.state.resultData);
    }
     
    render() {
        return (
            <div className="Testmodule">
                <div>Result Data</div>
                <Paper elevation={1}>
                    <Typography variant="h5" component="h3">
                        Requested log
                    </Typography>
                    <Typography component="p">A
                        {/* {this.state.resultData.toString()} */}
                    </Typography>
                </Paper>
            </div>
        );
    }
}

export default Testmodule;
